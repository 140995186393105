<template>
  <div class="newuserbox">
    <div class="headbox">
      <div class="headimg">
        <img :src="user.avatar" alt="" />
      </div>
      <div
        class="setbox"
        @click="
          () => {
            this.setshow = true;
          }
        "
      >
        <img src="../../assets/images/newuser/mb-user-headicon.png" alt="" />
      </div>
      <div class="infolist">
        <div class="userinfo">
          <div class="username">{{ user.name }}</div>
          <div @click="userIdShow = !userIdShow">
            ID:
            {{
              !userIdShow
                ? (user?.id + "").replace(
                    (user?.id + "").substring(1, 5),
                    "****"
                  )
                : user?.id
            }}
          </div>
        </div>
        <div class="priceinfo">
          <div>
            <div class="price1">
              <Cprice
                :size="0.12"
                :price="
                  Math.round(
                    (Number(user.bean) + Number(user.integral)) * 100
                  ) / 100
                "
              />
            </div>
            <div class="price2" style="margin-bottom: 0.05rem">
              <img src="../../assets/images/about/user/zidan.png" alt="" />
              {{ user.ammunition_energy }}
            </div>
            <div class="price1">
              <Cintegral
                :size="0.12"
                :price="Math.round(Number(user.score) * 100) / 100"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="steambox">
        <div class="setsteam" @click="bindsteam(user)">
          <div class="set1">
            设置Steam交易链接
            <img
              src="../../assets/images/newuser/mb-user-righticon.png"
              alt=""
            />
          </div>
          <div class="set2" v-if="user.steam_url == ''">
            点击此处前往设置交易链接
          </div>
          <div class="set2" v-if="user.steam_url !== ''">
            {{ user.steam_url }}
          </div>
        </div>
        <!-- <div class="recharge">
          <div class="rechargebg" @click="goreachange">立即充值</div>
        </div> -->
      </div>
    </div>
    <!-- <div class="pricelistbox">
      <div class="lbox">
        <div style="display: flex; align-items: center;margin-bottom: 0.06rem">
          <p>总充值:</p>
          <Cprice :size="0.12" :price="Totalrecharge"></Cprice>
        </div>
        <div style="display: flex; align-items: center">
          <p>月充值:</p>
          <Cprice :size="0.12" :price="monthlyrecharge"></Cprice>
        </div>

      </div>
      <div class="rbox">
        <div style="display: flex; align-items: center;margin-bottom: 0.06rem">
          <p>总取回:</p>
          <Cint :size="0.12" :price="Totalretrieval"></Cint>
        </div>
        <div style="display: flex; align-items: center">
          <p>月取回:</p>
          <Cint :size="0.12" :price="monthlyretrieval"></Cint>
        </div>
      </div>
    </div> -->
    <div class="clickbox">
      <div class="box1" @click="nameshow">
        <img
          class="img1"
          src="../../assets/images/newuser/mb-user-IDicon.png"
          alt=""
        />
        <div class="boxname">实名认证</div>
        <img
          class="img2"
          src="../../assets/images/newuser/mb-user-righticon.png"
        />
      </div>
      <a
        class="box1"
        target="_blank"
        :href="`mqqwpa://im/chat?chat_type=wpa&uin=${querydata.service_qq}&version=1&src_type=web&web_src=oicqzone.com`"
      >
        <img
          class="img1"
          src="../../assets/images/newuser/mb-user-callicon.png"
          alt=""
        />
        <div class="boxname">联系客服</div>
        <img
          class="img2"
          src="../../assets/images/newuser/mb-user-righticon.png"
        />
      </a>
    </div>
    <div class="itembox">
      <div
        class="item"
        v-for="item in clickitemdata"
        :key="item.id"
        @click="goto(item.id)"
      >
        <div class="iteminfo">
          <img :src="item.img" alt="" />
          <div>{{ item.title }}</div>
        </div>
      </div>
    </div>
    <!-- 绑定steam -->
    <van-overlay
      :lock-scroll="false"
      @mousewheel.prevent.native="() => {}"
      :show="steamshow"
    >
      <div class="showbox">
        <div class="itembox">
          <div class="title">
            steam交易链接
            <img
              @click="
                () => {
                  this.steamshow = false;
                }
              "
              src="../../assets/images/new/bg7.png"
              alt=""
            />
          </div>
          <div class="line"></div>
          <div class="namecenter">
            <div class="changeputbox">
              <span>手机号码:</span>
              <input
                class="putcalss"
                type="text"
                v-model.number="phonevalue"
                placeholder="请输入手机号"
              />
            </div>
            <div class="changeputbox yzmputbox">
              <span>验证码:</span>
              <input
                class="putcalss"
                type="text"
                v-model="steamphoneyzm"
                placeholder="请输入验证码"
              />
              <div class="yzmbtn point" @click="getsteamyzm">获取验证码</div>
            </div>
            <div class="changeputbox">
              <span>输入Steam:</span>
              <input
                class="putcalss"
                type="text"
                placeholder="请输入Steam交易链接"
                v-model="steam_url"
              />
            </div>
          </div>
          <div class="btnbox">
            <div class="btn" @click="GetSteamUrl">确定</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <!-- 实名认证 -->
    <van-overlay
      :lock-scroll="false"
      @mousewheel.prevent.native="() => {}"
      :show="namebindshow"
    >
      <div class="showbox">
        <div class="itembox">
          <div class="title">
            实名认证
            <img
              @click="
                () => {
                  this.namebindshow = false;
                }
              "
              src="../../assets/images/new/bg7.png"
              alt=""
            />
          </div>
          <div class="line"></div>
          <div class="content">
            <div class="putbox">
              <div class="stitle">真实姓名</div>
              <input
                class="put"
                type="text"
                placeholder="请输入真实姓名"
                v-model="namevalue"
              />
            </div>
            <div class="putbox">
              <div class="stitle">身份证号</div>
              <input
                class="put"
                type="text"
                placeholder="请输入真实身份证号"
                v-model="nameID"
              />
            </div>
          </div>
          <div class="btnbox">
            <div class="btn" @click="doentername">确定</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <!-- 设置 -->
    <van-overlay
      :lock-scroll="false"
      @mousewheel.prevent.native="() => {}"
      :show="setshow"
    >
      <div class="showbox">
        <div class="itembox">
          <div class="title">
            设置
            <img
              @click="
                () => {
                  this.setshow = false;
                }
              "
              src="../../assets/images/new/bg7.png"
              alt=""
            />
          </div>
          <div class="line"></div>
          <div class="content">
            <div class="sethead">
              <div class="headimg" @click="toggleShow">
                <div class="headimgbox">
                  <img :src="user.avatar" alt="" />
                </div>
                <img
                  class="seticon"
                  src="../../assets/images/newuser/mb-user-seticon.png"
                  alt=""
                />
              </div>
              <div class="setinfobox">
                <div class="list1">
                  <span>昵称</span>
                  <div class="nikename" @click="toggleShow">
                    {{ user.name }}
                  </div>
                  <img
                    @click="toggleShow"
                    class="seticon"
                    src="../../assets/images/newuser/mb-user-seticon.png"
                    alt=""
                  />
                </div>
                <div
                  class="list1"
                  @click="
                    () => {
                      (this.code_bangdingshow = true), (this.setshow = false);
                    }
                  "
                  v-if="inviter_id == 0"
                >
                  <span>上级邀请码</span>
                  <div class="nikename"></div>
                  <img
                    class="seticon"
                    src="../../assets/images/newuser/mb-user-seticon.png"
                    alt=""
                  />
                </div>
                <div class="list1" v-if="inviter_id !== 0">
                  <span>上级邀请码</span>
                  <div class="nikename" v-if="inviter_id !== 0">
                    {{ invite_code2 }}
                  </div>
                </div>
              </div>
            </div>
            <div class="line"></div>
            <div class="setphonebox">
              <div class="msgsettitle">
                <div class="titlebg">信息设置</div>
              </div>
              <div class="list2" @click="clickpass">
                <span>修改密码</span>
                <div class="nikename">******</div>
                <img
                  class="seticon"
                  src="../../assets/images/newuser/mb-user-seticon.png"
                  alt=""
                />
              </div>
              <div class="list2">
                <span>手机号码</span>
                <div class="nikename">
                  {{
                    this.mobile?.slice(0, 3) + "****" + this.mobile?.slice(-4)
                  }}
                </div>
              </div>
            </div>
            <div class="tipsbox">
              <div
                class="tipsitem"
                v-for="item in ArticleData"
                :key="item.id"
                @click="Toarticle(item)"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
          <div class="btnbox">
            <div class="btn" @click="loginOut()">退出登录</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <!-- 修改头像昵称 -->
    <van-overlay @mousewheel.prevent.native="() => {}" :show="usershow">
      <div class="showbox">
        <div class="changeheadbox">
          <div class="title">
            修改昵称头像
            <img
              @click="
                () => {
                  this.usershow = false;
                }
              "
              src="../../assets/images/new/bg7.png"
              alt=""
            />
          </div>
          <div class="line"></div>
          <div class="nikenamebox">
            <div class="upimgbox">
              <img :src="isupdataimg" alt="" />
            </div>
            <div class="putnamebox">
              <input
                class="putname"
                v-model="namevalu"
                type="text"
                placeholder="玩家昵称"
              />
            </div>
          </div>
          <div class="imgbox">
            <div class="imgcenter">
              <div class="addimg point" @click="uploadbease">
                <img
                  src="../../assets/images/newuser/mb-user-addhead.png"
                  alt=""
                />
                <input
                  style="display: none"
                  type="file"
                  id="upload"
                  accept="image/*"
                  @change="handleFileUpload($event)"
                />
              </div>
              <div v-for="(v, i) in imgurlvalue" :key="i">
                <div
                  class="l_imgbox point"
                  :class="changehead === i ? 'acimgbox' : ''"
                  @click="imgchange(v, i)"
                >
                  <img :src="v.url" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="enterbtnbox">
            <div class="namebtn point nb2" @click="updata">保存</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <!-- 修改密码 -->
    <van-overlay @mousewheel.prevent.native="() => {}" :show="passwordshow">
      <div class="showbox passwordbox" v-show="passwordshow">
        <div v-if="put1" class="itembox">
          <div class="title">
            修改密码
            <img
              @click="
                () => {
                  this.passwordshow = false;
                }
              "
              src="../../assets/images/new/bg7.png"
              alt=""
            />
          </div>
          <div class="line"></div>
          <div class="namecenter">
            <div class="changeputbox">
              <span>手机号码:</span>
              <input
                class="putcalss"
                type="text"
                v-model.number="phonevalue"
                placeholder="请输入手机号"
              />
            </div>
            <div class="changeputbox yzmputbox">
              <span>验证码:</span>
              <input
                class="putcalss"
                type="text"
                v-model="passyzm"
                placeholder="请输入验证码"
              />
              <div class="yzmbtn point" @click="getpassyzm">获取验证码</div>
            </div>
            <div class="changeputbox">
              <span>设置新密码:</span>
              <input
                class="putcalss"
                type="password"
                v-model="newpass"
                placeholder="请输入新密码"
              />
            </div>
            <div class="changeputbox">
              <span>确认新密码:</span>
              <input
                class="putcalss"
                type="password"
                v-model="enterpass"
                placeholder="再次输入新密码"
              />
            </div>
          </div>
          <div class="updatabtnbox">
            <div class="updatabtn" @click="dopass">下一步</div>
          </div>
        </div>
        <!-- <div v-else-if="put2">
          <div class="titlebox">
            <p>修改结果</p>
            <img class="closeicon point" src="../../assets/images/new/bg7.png" alt="" @click="() => {this.passwordshow = false}">
          </div>
          <div class="line"></div>
          <div class="okbox">
            <div class="iconinfo">
              <img src="../../assets/images/NewUser/pc-newuser-okicon.png" alt="">
              <p>修改成功</p>
            </div>
          </div>
          <div class="updatabtnbox">
            <div class="updatabtn" @click="backlogin">返回首页</div>
          </div>
        </div> -->
      </div>
    </van-overlay>
    <!-- 绑定邀请码 -->
    <van-overlay
      :lock-scroll="false"
      @mousewheel.prevent.native="() => {}"
      :show="code_bangdingshow"
    >
      <div class="showbox">
        <div class="itembox">
          <div class="title">
            绑定邀请码
            <img
              @click="
                () => {
                  (this.code_bangdingshow = false),
                    (this.invite_code_bangding = '');
                }
              "
              src="../../assets/images/new/bg7.png"
              alt=""
            />
          </div>
          <div class="line"></div>
          <div class="content">
            <input
              type="text"
              placeholder="请输入您的邀请码"
              v-model="invite_code_bangding"
            />
          </div>
          <div class="btnbox">
            <div class="btn" @click="setinvite_code">确定</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <Tabbar :activeIndex="2" />
  </div>
</template>
<script>
import {
  PersonalInfo,
  SteamUrl,
  Set_Name,
  set_inviter,
  checkNameAndCardId,
  getrechargeinfoData,
  Set_avatar,
  base64,
  PostSendSms,
  Reset_password,
  getArticleData,
  Info,
} from "@/network/api.js";
import { mapState, mapMutations, mapActions } from "vuex";
var myreg = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/; //设置手机号正则表达式
var ID =
  /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/; //身份证正则
var reg = /^[\w]{6,12}$/; //密码正则最少6位最多12，包含字母数字
import { imgurlvalue } from "./index";
import Cint from "../../components/common/Cint.vue";
export default {
  components: { Cint },
  name: "about",
  data() {
    return {
      userIdShow: false,
      setshow: false,
      phoneShow: false,
      code_bangdingshow: false,
      Name: "",
      // invite_code: "",
      Email: "",
      created_at: "",
      updated_at: "",
      mobile: "",
      steamphoneyzm: "",

      steam_url: "",
      steam_id: "",
      invite_code_bangding: "",
      inviter_id: 0,
      inviter: {},
      invite_code2: "",
      message: {},
      //实名认证相关
      namebindshow: false, //实名认证绑定
      namevalue: "", //姓名
      nameID: "", //身份证号码
      //手机绑定相关
      phonebindshow: false, //绑定手机弹窗
      put1: false, //绑定1
      put2: false, //绑定2
      put3: false, //绑定3
      phonevalue: "", //手机号
      phoneyzm: "", //手机绑定验证码

      passwordshow: false, //修改密码弹窗
      passyzm: "", //修改密码验证码
      newpass: "", //新密码
      enterpass: "", //确认新密码
      Totalrecharge: 0, //总充值金额
      monthlyrecharge: 0, //月充值
      Totalretrieval: 0, //总取回
      monthlyretrieval: 0, //月取回
      //头像上传
      isupdataimg: "", //更新时的头像显示
      usershow: false, //用户修改头像和修改昵称开关
      imgurlvalue, //模拟头像
      changehead: 0, //选择头像change
      namevalu: "", //用户输入的昵称
      file: null, //用户选择文件
      flag: true,
      clickitemdata: [
        {
          id: 1,
          img: require("../../assets/images/newuser/mb-user-itemicon1.png"),
          title: "我的背包",
        },
        {
          id: 2,
          img: require("../../assets/images/newuser/mb-user-itemicon2.png"),
          title: "充值",
        },
        {
          id: 3,
          img: require("../../assets/images/newuser/mb-user-itemicon3.png"),
          title: "分享奖励",
        },
        {
          id: 4,
          img: require("../../assets/images/newuser/mb-user-itemicon4.png"),
          title: "帮助中心",
        },
      ],
      steamshow: false,
      ArticleData: [], //信息数据列表
      // 客服图片链接，qq群图片跳转链接
      querydata: {
        service_qq: "",
      },
      isAllowRealName: true,
    };
  },
  created() {
    this.GetPersonalInfo(this.user);
    this.getrecheargedata();
    this.gettipsdata();
    this.GetInfo();
  },
  mounted() {
    setTimeout(() => {
      document.getElementById("ymwl-kefu").style.display = "block";
      document
        .getElementById("ymwl-kefu")
        .classList.remove("my-custom-styles1");
      document.getElementById("ymwl-kefu").classList.add("my-custom-styles");
    }, 100);
  },
  methods: {
    nameshow() {
      let info = this.user;
      if (info.true_name) {
        this.$message.error("已经实名，请勿重复填写");
      } else {
        this.namebindshow = true;
        this.put1 = true;
      }
    },

    GetInfo() {
      Info().then((res) => {
        this.querydata = res.data.data;
      });
    },
    clickpass() {
      this.passwordshow = true;
      this.put1 = true;
      this.setshow = false;
    },
    // 获得隐私信息列表
    gettipsdata() {
      getArticleData().then((res) => {
        this.ArticleData = res.data.data;
      });
    },
    Toarticle(v) {
      this.$router.push({
        path: "/Datapage",
        query: {
          id: v.id,
        },
      });
    },
    bindsteam(v) {
      this.steamshow = true;
    },
    goreachange() {
      this.$router.push("/BagRecharge");
    },
    goto(v) {
      switch (v) {
        case 1:
          this.$router.push("/Warehouse");
          break;
        case 2:
          this.$router.push("/BagRecharge");
          break;
        case 3:
          this.$router.push("/shareReward");
          break;
        case 4:
          this.$router.push("/Tipspage");
          break;
      }
    },
    //头像上传区域
    toggleShow() {
      this.usershow = !this.usershow;
      this.namevalu = this.user.name;
      this.isupdataimg = this.user.avatar;
      this.setshow = !this.setshow;
      this.changehead = 0;
    },
    // 选择头像改变
    imgchange(v, i) {
      this.changehead = i;
      this.isupdataimg = v.url;
    },
    //用户点击自定义上传
    handleFileUpload(e) {
      const files = e.target.files; // 获取选择的文件列表
      const fileSize = files[0].size; // 获取第一个文件的大小，以字节为单位
      // 检查文件大小是否超过 512KB
      if (fileSize > 512 * 1024) {
        this.$message({
          message: "文件大小超过限制，最大允许上传 512KB 的图片。",
          type: "error",
        });
        return; // 停止执行上传操作
      } else {
        var reader = new FileReader();
        reader.onload = (e) => {
          let beas = e.target.result;
          base64(beas).then((res) => {
            if (res.data.code == 200) {
              this.isupdataimg = res.data.data.url;
            } else {
              this.$message({
                message: res.data.message,
                type: "error",
              });
            }
          });
        };
        reader.readAsDataURL(e.target.files[0]);
      }
    },
    uploadbease() {
      // 头像点击上传
      this.show = !this.show;
      let filedom = document.getElementById("upload");
      filedom.click();
    },

    // 用户点击确认
    updata() {
      // 获取用户输入昵称
      let res = this.namevalu;
      // 昵称输入框正则验证
      let reg = /^[\u4e00-\u9fa5\w]{2,8}$/;
      if (!reg.test(res)) {
        this.$message({
          message:
            "最少输入2个字符，最多输入8个字符，可以包含汉字、字母、下划线",
          type: "error",
        });
      } else {
        Set_Name(res).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.GetPersonalInfo();
            this.usershow = false;
          }
        });
        //头像修改
        Set_avatar(this.isupdataimg).then((res) => {
          PersonalInfo().then((res) => {
            this.User(res.data.data);
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.GetPersonalInfo();
            this.usershow = false;
          });
        });
      }
    },
    //头像上传区域
    getrecheargedata() {
      getrechargeinfoData().then((res) => {
        let data = res.data.data;
        if (res.data.code == 200) {
          this.Totalrecharge = data.total_recharge;
          this.monthlyrecharge = data.month_recharge;
          this.Totalretrieval = data.total_extract;
          this.monthlyretrieval = data.month_extract;
        } else {
          this.$message({
            message: res.data.data.message,
            type: "error",
          });
        }
      });
    },
    ...mapActions({
      MyInfo: "Refresh",
    }),
    doenter() {
      this.put1 = false;
      this.put2 = true;
    },
    // 判断是否满18岁
    checkID18(IDCard) {
      //获取用户身份证号码
      var userCard = IDCard;
      //如果身份证号码为undefind则返回空
      if (!userCard) {
        return false;
      }
      //获取出生年月日
      var yearBirth = userCard.substring(6, 10);
      var monthBirth = userCard.substring(10, 12);
      var dayBirth = userCard.substring(12, 14);
      //获取当前年月日并计算年龄
      var myDate = new Date();
      var monthNow = myDate.getMonth() + 1;
      var dayNow = myDate.getDate();
      var age = myDate.getFullYear() - yearBirth;
      if (
        monthNow < monthBirth ||
        (monthNow == monthBirth && dayNow < dayBirth)
      ) {
        age--;
      }
      if (age >= 18) {
        return true;
      }
      return false;
    },
    //实名认证
    doentername() {
      if (!this.isAllowRealName) {
        this.$message.error("请勿频繁点击，10秒后再次尝试");
        return false;
      }
      this.isAllowRealName = false;
      setTimeout(() => {
        this.isAllowRealName = true;
      }, 10000);
      if (!ID.test(this.nameID)) {
        //验证身份证格式
        this.$message.error("身份证格式错误");
        return false;
      } else {
        const result = this.checkID18(this.nameID);
        if (!result) {
          this.$message.error("未满18岁");
          return false;
        }
        const params = {
          true_name: this.namevalue,
          card_id: this.nameID,
        };
        if (this.flag) {
          this.flag = false;
          setTimeout(() => {
            this.flag = true;
          }, 3000);
          checkNameAndCardId(params).then((res) => {
            if (res.data.code === 200) {
              this.$message({
                message: "绑定成功",
                type: "success",
              });
              this.MyInfo();
            } else {
              this.$message({
                message: res.data?.message,
                type: "error",
              });
            }
          });
        }
      }
    },
    //实名认证完毕

    //修改密码
    getpassyzm() {
      //获取修改密码验证码
      if (this.phonevalue) {
        if (!myreg.test(this.phonevalue)) {
          //验证手机号格式是否正确
          this.$message({
            message: "手机号格式错误",
            type: "error",
          });
        } else {
          PostSendSms(this.phonevalue, 3).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: res.data.message,
                type: "success",
              });
            } else {
              this.$message({
                message: res.data.message,
                type: "error",
              });
            }
          });
        }
      } else {
        this.$message({
          message: "请输入手机号",
          type: "error",
        });
      }
    },
    dopass() {
      if (this.passyzm == "") {
        this.$message({
          message: "请输入验证码",
          type: "error",
        });
      } else {
        if (!reg.test(this.newpass, this.enterpass)) {
          this.$message({
            message: "密码格式错误,最少输入6位最多输入12位密码,包含字母数字",
            type: "error",
          });
        } else {
          if (this.newpass != this.enterpass) {
            this.$message({
              message: "两次密码输入错误",
              type: "error",
            });
          } else {
            Reset_password(this.phonevalue, this.enterpass, this.passyzm).then(
              (res) => {
                if (res.data.code == 200) {
                  this.$message({
                    message: res.data.message,
                    type: "success",
                  });
                  setTimeout(() => {
                    this.loginOut();
                  }, 1500);
                } else {
                  this.$message({
                    message: res.data.message,
                    type: "error",
                  });
                }
              }
            );
          }
        }
      }
    },
    // 获得steam手机验证码
    getsteamyzm() {
      if (!myreg.test(this.phonevalue)) {
        //验证手机号格式是否正确
        this.$message({
          message: "手机号格式错误",
          type: "error",
        });
      } else {
        //4用来验证手机
        PostSendSms(this.phonevalue, 4).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
      }
    },

    //退出
    loginOut() {
      this.GetIndividualsShow(false);
      this.Leave();
      this.$router.push("/Home");
    },

    ...mapMutations(["GetIndividualsShow", "Leave"]),
    //获取个人信息
    GetPersonalInfo(user) {
      if (user != undefined) {
        localStorage.setItem("boxUserInfo", JSON.stringify(user));
        this.message = user;
        this.steam_id = user.steam_id;
        this.Name = user.name;
        // this.invite_code = user.invite_code;
        this.Email = user.email;
        this.mobile = user.mobile;
        this.created_at = user.created_at;
        this.updated_at = user.updated_at;
        this.steam_url = user.steam_url;
        this.inviter_id = user.inviter_id;
        this.inviter = user.inviter;
        this.invite_code2 = user.inviter?.invite_code;
      }
    },

    // 更改名称
    PostName() {
      Set_Name(this.Name).then((res) => {
        this.$message({
          message: res.data.message,
          type: "success",
        });

        PersonalInfo().then((res) => {
          this.User(res.data.data);
        });
      });
    },
    //绑定邀请人
    setinvite_code() {
      if (this.invite_code_bangding == "") {
        this.$message({
          message: "请输入邀请码",
          type: "error",
        });
      } else {
        set_inviter(this.invite_code_bangding).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: res.data.message,
              type: "success",
            });
            window.location.reload();
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
          this.GetPersonalInfo();
        });
      }
    },

    //设置Steam连接
    GetSteamUrl() {
      if (this.steam_url == "") {
        this.$message({
          message: "请输入steam链接",
          type: "error",
        });
        return;
      }
      if (this.phonevalue == "" || this.steamphoneyzm == "") {
        this.$message({
          message: "请输入手机号和验证码",
          type: "error",
        });
        return;
      }

      let params = {
        steam_url: this.steam_url,
        verify: this.steamphoneyzm,
        mobile: this.phonevalue,
      };
      SteamUrl(params).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          this.steamshow = false;
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
          });
          this.steamshow = false;
        }

        this.GetPersonalInfo();
      });
    },
    ...mapMutations(["User"]),
  },
  computed: {
    ...mapState(["user"]),
  },
  watch: {
    user(v) {
      this.GetPersonalInfo(v);
    },
  },
};
</script>
<style scoped lang="scss">
.newuserbox {
  padding-top: 0.5rem;
  width: 3.35rem;
  margin: 0 auto;
  .headbox {
    width: 100%;
    height: 1.44rem;
    background: url("../../assets/images/newuser/mb-user-headbg.png") no-repeat;
    background-size: 100%;
    position: relative;
    .headimg {
      position: absolute;
      left: 0.39rem;
      top: -0.25rem;
      width: 0.66rem;
      height: 0.66rem;
      background: url("../../assets/images/newuser/mb-user-headimgbg.png")
        no-repeat;
      background-size: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 0.49rem;
        height: 0.49rem;
        border-radius: 50%;
      }
    }
    .setbox {
      width: 100%;
      height: 0.41rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 0.15rem;
      img {
        width: 0.16rem;
      }
    }
    .infolist {
      width: 100%;
      height: 0.41rem;
      display: flex;
      margin-top: 0.06rem;
      .userinfo {
        width: 1.47rem;
        height: 100%;
        text-align: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 0.12rem;
        color: #c6c6fc;
        line-height: 0.15rem;
        .username {
          margin-bottom: 0.05rem;
        }
      }
      .priceinfo {
        width: 1.84rem;
        height: 100%;
        background: url("../../assets/images/newuser/mb-user-headbg1.png")
          no-repeat;
        background-size: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .price1 {
          width: 100%;
          display: flex;
          margin-bottom: 0.05rem;
        }
        .price2 {
          width: 100%;
          display: flex;
          align-items: center;
          color: rgb(236, 177, 5);
          font-weight: 600;
          font-size: 0.12rem;
          img {
            width: 0.12rem;
            margin-right: 0.03rem;
          }
        }
      }
    }
    .steambox {
      width: 100%;
      height: 0.4rem;
      display: flex;
      .setsteam {
        width: 2rem;
        height: 100%;
        .set1 {
          width: 1.4rem;
          height: 0.18rem;
          background: url("../../assets/images/newuser/mb-user-headbg2.png")
            no-repeat;
          background-size: 100%;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 0.1rem;
          color: #ffffff;
          line-height: 0.18rem;
          padding: 0 0.08rem;
          display: flex;
          align-items: center;
          img {
            width: 0.06rem;
            margin-left: 0.12rem;
          }
        }
        .set2 {
          margin-top: 0.02rem;
          width: 2rem;
          height: 0.2rem;
          background: url("../../assets/images/newuser/mb-user-headbg3.png")
            no-repeat;
          background-size: 100%;
          font-family: PingFang SC, PingFang SC;
          font-weight: 300;
          font-size: 0.1rem;
          color: #c1bdd9;
          padding: 0 0.06rem;
          line-height: 0.2rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .recharge {
        width: 1.34rem;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .rechargebg {
          width: 0.9rem;
          height: 0.26rem;
          background: url("../../assets/images/newuser/mb-user-rechangeicon.png")
            no-repeat;
          background-size: 100%;
          padding: 0.05rem 0.19rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-style: italic;
          font-size: 0.12rem;
          color: #ffa439;
        }
      }
    }
  }
  .pricelistbox {
    width: 100%;
    height: 0.6rem;
    background: url("../../assets/images/newuser/mb-user-pricelistbg.png")
      no-repeat;
    background-size: 100%;
    margin-top: 0.1rem;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .lbox {
      width: 50%;
      padding: 0 0.12rem;
    }
    .rbox {
      width: 50%;
      padding: 0 0.12rem;
    }
    p {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 0.12rem;
      color: #c5bbed;
      margin-right: 0.12rem;
    }
  }
  .clickbox {
    width: 100%;
    height: 0.36rem;
    display: flex;
    justify-content: space-between;
    margin-top: 0.12rem;
    .box1 {
      width: 1.63rem;
      height: 0.36rem;
      display: flex;
      align-items: center;
      border: 0.01rem solid #4a3588;
      background: rgba(4, 12, 34, 0.6);
      border-radius: 0.02rem;
      justify-content: center;
      .boxname {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 0.12rem;
        color: #ffffff;
        margin-right: 0.45rem;
      }
      .img1 {
        width: 0.18rem;
        margin-right: 0.08rem;
      }
      .img2 {
        width: 0.08rem;
      }
    }
  }
  .itembox {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      width: 1.6rem;
      height: 0.9rem;
      border: 0.01rem solid #414273;
      background: rgba(4, 12, 34, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0.12rem;
      border-radius: 0.02rem;
      .iteminfo {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 0.12rem;
        color: #ffffff;
        text-align: center;
        img {
          width: 0.44rem;
          margin: 0 auto 0.12rem;
        }
      }
    }
  }
  .showbox {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .itembox {
      width: 3.35rem;
      border: 0.01rem solid #5d5d9b;
      background: #08081e;
      .title {
        width: 100%;
        height: 0.38rem;
        text-align: center;
        line-height: 0.38rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 0.16rem;
        color: #dfdbff;
        position: relative;
        img {
          position: absolute;
          right: 0.2rem;
          top: 0.1rem;
          width: 0.12rem;
        }
      }
      .line {
        width: 100%;
        border: 0.01rem solid;
        border-image: linear-gradient(
            90deg,
            rgba(93, 93, 155, 0),
            rgba(93, 93, 155, 1),
            rgba(93, 93, 155, 0)
          )
          1 1;
      }
      .content {
        padding: 0.2rem 0;
        margin: 0 auto;
        input {
          width: 2.5rem;
          height: 0.23rem;
          border: 0.01rem solid #5d5d9b;
          background: #08081e;
          font-family: PingFang SC, PingFang SC;
          font-weight: 300;
          font-size: 0.08rem;
          color: rgba(191, 191, 237, 0.5);
          text-indent: 0.08rem;
        }
      }
      .sethead {
        width: 100%;
        height: 0.66rem;
        display: flex;
        margin-bottom: 0.15rem;
        .headimg {
          width: 1.08rem;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          .headimgbox {
            width: 0.66rem;
            height: 0.66rem;
            border-radius: 50%;
            border: 0.01rem solid #5d5d9b;
            padding: 0.06rem;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }

          .seticon {
            position: absolute;
            right: 0.26rem;
            bottom: 0rem;
            width: 0.16rem;
            border: 0;
            padding: 0;
            border-radius: unset;
          }
        }
        .setinfobox {
          width: 1.84rem;
          height: 100%;
          .list1 {
            display: flex;
            align-items: center;
            margin-bottom: 0.14rem;
            span {
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              font-size: 0.12rem;
              color: #dfdbff;
              margin-right: 0.09rem;
            }
            .nikename {
              padding: 0 0.1rem;
              height: 0.17rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              font-size: 0.12rem;
              color: #9894b4;
              border-bottom: 0.01rem solid;
              border-image: linear-gradient(
                  90deg,
                  rgba(93, 93, 155, 0),
                  rgba(93, 93, 155, 1),
                  rgba(93, 93, 155, 0)
                )
                1 1;
            }
            .seticon {
              width: 0.16rem;
              margin-left: 0.12rem;
            }
          }
        }
      }
      .setphonebox {
        width: 2.54rem;
        margin: 0 auto;
        .msgsettitle {
          width: 100%;
          height: 0.44rem;
          display: flex;
          justify-content: center;
          align-items: center;
          .titlebg {
            width: 0.74rem;
            height: 0.18rem;
            background: url("../../assets/images/Roll/room/mb-roll-title.png")
              no-repeat;
            background-size: 100% 100%;
            text-align: center;
            line-height: 0.18rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 0.12rem;
            color: #ffffff;
          }
        }
        .list2 {
          display: flex;
          align-items: center;
          margin-bottom: 0.14rem;
          span {
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 0.12rem;
            color: #dfdbff;
            margin-right: 0.09rem;
          }
          .nikename {
            width: 1.6rem;
            height: 0.17rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 0.12rem;
            text-align: center;
            color: #9894b4;
            border-bottom: 0.01rem solid;
            border-image: linear-gradient(
                90deg,
                rgba(93, 93, 155, 0),
                rgba(93, 93, 155, 1),
                rgba(93, 93, 155, 0)
              )
              1 1;
          }
          .seticon {
            width: 0.16rem;
            margin-left: 0.12rem;
          }
        }
      }
      .tipsbox {
        display: flex;
        flex-wrap: wrap;
        width: 3rem;
        margin: 0 auto;
        .tipsitem {
          padding: 0.02rem 0.13rem;
          background: linear-gradient(135deg, #282845 100%, #12122f 100%);
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 0.12rem;
          color: #606093;
          margin: 0.05rem 0.11rem;
        }
      }
      .putbox {
        width: 2.25rem;
        .stitle {
          font-family: PingFang SC, PingFang SC;
          font-weight: 300;
          font-size: 0.1rem;
          color: #dfdbff;
          margin: 0.12rem 0;
        }
      }
      .btnbox {
        width: 100%;
        margin: 0.15rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .btn {
          width: 0.9rem;
          height: 0.26rem;
          text-align: center;
          line-height: 0.26rem;
          background: linear-gradient(135deg, #242448 0%, #42426d 100%);
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.14rem;
          color: #ffffff;
        }
      }
    }
  }
  .changeheadbox {
    width: 3.35rem;
    height: 3.22rem;
    background: rgba(8, 8, 30, 0.8);
    border-radius: 0.04rem;
    border: 0.02rem solid #5d5d9b;
    .title {
      width: 100%;
      height: 0.38rem;
      text-align: center;
      line-height: 0.38rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 0.16rem;
      color: #dfdbff;
      position: relative;
      img {
        position: absolute;
        right: 0.2rem;
        top: 0.1rem;
        width: 0.12rem;
      }
    }
    .line {
      width: 100%;
      margin: 0 auto;
      height: 0;
      border: 0.01rem solid;
      border-image: linear-gradient(
          90deg,
          rgba(93, 93, 155, 0),
          rgba(93, 93, 155, 1),
          rgba(93, 93, 155, 0)
        )
        1 1;
    }
    .nikenamebox {
      width: 2.8rem;
      height: 0.96rem;
      display: flex;
      align-items: center;
      .upimgbox {
        width: 0.54rem;
        height: 0.54rem;
        margin: 0 0.2rem;
        border-radius: 50%;
        border: 0.01rem solid #5d5d9b;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 0.47rem;
          height: 0.47rem;
          border-radius: 50%;
        }
      }
      .putnamebox {
        width: 1.5rem;
        display: flex;
        justify-content: center;
        .putname {
          width: 1.5rem;
          height: 0.23rem;
          margin: 0.17rem auto;
          border: 0.01rem solid #32325d;
          outline: none;
          text-align: center;
          background: #08081e;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.12rem;
          color: #bfbfed;
        }
      }
    }
    .imgbox {
      width: 3rem;
      margin: 0 auto;
      .imgcenter {
        width: 3rem;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
      }
      .addimg {
        width: 0.5rem;
        height: 0.5rem;
        margin-right: 0.08rem;
        margin-top: 0.08rem;
        img {
          width: 100%;
        }
      }
      .l_imgbox {
        width: 0.5rem;
        height: 0.5rem;
        margin-right: 0.08rem;
        margin-top: 0.08rem;
        img {
          width: 100%;
          z-index: 1;
        }
      }
      .acimgbox {
        width: 0.5rem;
        height: 0.5rem;
        background: url("../../assets/images/newuser/mb-user-acheadbg.png")
          no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 0.47rem;
        }
      }
    }
    .enterbtnbox {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 0.14rem;
      .namebtn {
        width: 0.9rem;
        height: 0.26rem;
        text-align: center;
        line-height: 0.26rem;
        border-radius: 0.02rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 0.14rem;
        color: #ffffff;
      }
      .nb2 {
        border: 0.01rem solid #5a5a9a;
        background: linear-gradient(90deg, #242448 100%, #42426d 100%);
      }
    }
  }
  .namecenter {
    width: 2.5rem;
    margin: 0.13rem auto;
  }
  .changeputbox {
    width: 100%;
    margin: 0.12rem auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    span {
      width: 0.9rem;
      height: 0.22rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 300;
      font-size: 0.1rem;
      color: #dfdbff;
      margin-right: 0.1rem;
    }
    .putcalss {
      width: 2.55rem;
      height: 0.23rem;
      text-decoration: none;
      border: 0.01rem solid #5d5d9b;
      font-family: PingFang SC, PingFang SC;
      font-weight: 300;
      font-size: 0.08rem;
      color: rgba(191, 191, 237, 0.5);
      text-indent: 0.12rem;
    }
  }
  .yzmputbox {
    margin: 0 auto;
    display: flex;
    align-items: center;
    position: relative;
    .putcalss {
      width: 1.8rem;
    }
    .yzmbtn {
      width: 0.65rem;
      height: 0.23rem;
      text-align: center;
      line-height: 0.23rem;
      background: linear-gradient(135deg, #242448 0%, #42426d 100%);
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 0.1rem;
      color: #ffffff;
      margin-left: 0.04rem;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  .updatabtnbox {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 0.1rem;
    .updatabtn {
      width: 0.98rem;
      height: 0.28rem;
      text-align: center;
      line-height: 0.28rem;
      background: linear-gradient(135deg, #242448 0%, #42426d 100%);
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 0.12rem;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
</style>
